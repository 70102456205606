import React, { useEffect, useState, useMemo } from "react";
import MDTypography from "components/MDTypography";
import DataTable from "customComponents/Tables/DataTable";
import { getSensorsData } from "service/service";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { TextField } from "@mui/material";
import MDButton from "components/MDButton";

export default function SensorsHistoryDataTable() {
	const [data, setData] = useState([]);
	const [deviceIdFilter, setDeviceIdFilter] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const pageSize = 100;

	const fetchSensors = async (deviceId, page, startDate, endDate) => {
		try {
			const response = await getSensorsData(deviceId, page, pageSize, startDate, endDate);
			setTotalPages(response.totalPages);
			if (response.data.length > 0) {
				setData(response.data);
			} else {
				setData([]);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchSensors(deviceIdFilter, page, startDate, endDate);
	}, [deviceIdFilter, page, startDate, endDate]);

	const handlePageChange = (currentPage) => {
		setPage(currentPage);
	};

	const handleResetFilters = () => {
		setDeviceIdFilter("");
		setStartDate("");
		setEndDate("");
		setPage(1);
	};

	const rows =
		data.length > 0
			? data.map((item) => ({
					timestamp: (
						<MDTypography variant="caption" color="text">
							{new Date(item.timestamp).toLocaleString()}
						</MDTypography>
					),
					deviceId: (
						<MDTypography variant="caption" color="text">
							{item.deviceId}
						</MDTypography>
					),
					temperature: (
						<MDTypography variant="caption" color="text">
							{item.temperature + " C" ?? "N/A"}
						</MDTypography>
					),
					humidity: (
						<MDTypography variant="caption" color="text">
							{item.humidity + " %" ?? "N/A"}
						</MDTypography>
					),
					tilt: (
						<MDTypography variant="caption" color="text">
							{item.tilt ?? "N/A"}
						</MDTypography>
					),
					rpm: (
						<MDTypography variant="caption" color="text">
							{item.rpm + " l(s)" ?? "N/A"}
						</MDTypography>
					),
					power: (
						<MDTypography variant="caption" color="text">
							{item.power + " W" ?? "N/A"}
						</MDTypography>
					),
					// hallDigital: (
					// 	<MDTypography variant="caption" color="text">
					// 		{item.hallDigital ?? "N/A"}
					// 	</MDTypography>
					// ),
					// hallAnalog: (
					// 	<MDTypography variant="caption" color="text">
					// 		{item.hallAnalog ?? "N/A"}
					// 	</MDTypography>
					// ),
			  }))
			: [];

	const columns = useMemo(
		() => [
			{ Header: "Date", accessor: "timestamp", align: "center" },
			{ Header: "Device", accessor: "deviceId", align: "left" },
			{ Header: "Temperature", accessor: "temperature", align: "left" },
			{ Header: "Humidity", accessor: "humidity", align: "center" },
			// { Header: "Hall Digital", accessor: "hallDigital", align: "center" },
			// { Header: "Hall Analog", accessor: "hallAnalog", align: "center" },
			{ Header: "Tilt", accessor: "tilt", align: "center" },
			{ Header: "L/s", accessor: "rpm", align: "center" },
			{ Header: "Power", accessor: "power", align: "center" },
		],
		[]
	);

	return (
		<>
			<MDBox
				display="flex"
				bgColor="white"
				alignItems="center"
				justifyContent="space-between"
				borderRadius="10px"
				p={2}
				mb={2}>
				<MDBox display="flex" alignItems="center" gap="16px">
					<MDInput
						type="text"
						label="Device"
						onChange={(event) => {
							setPage(1);
							setDeviceIdFilter(event.target.value);
						}}
						width="300px"
					/>
					<MDBox display="flex" alignItems="center" gap="4px">
						<TextField
							label="Start Date"
							type="date"
							InputLabelProps={{
								shrink: true,
							}}
							value={startDate}
							onChange={(event) => {
								setPage(1);
								setStartDate(event.target.value);
							}}
						/>
						-
						<TextField
							label="End Date"
							type="date"
							InputLabelProps={{
								shrink: true,
							}}
							value={endDate}
							onChange={(event) => {
								setPage(1);
								setEndDate(event.target.value);
							}}
						/>
					</MDBox>
				</MDBox>
				<MDBox>
					<MDButton variant="outlined" color="primary" onClick={handleResetFilters}>
						Reset Filters
					</MDButton>
				</MDBox>
			</MDBox>
			<DataTable
				table={{
					columns,
					rows,
				}}
				isSorted={true}
				entriesPerPage={{ defaultValue: pageSize, display: false }}
				showTotalEntries={false}
				pagination={{
					size: "large",
					variant: "contained",
					active: true,
					onPageChange: handlePageChange,
					totalPages: totalPages,
					currentPage: page,
				}}
			/>
		</>
	);
}
